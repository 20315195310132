<template>
  <v-responsive :aspect-ratio="3">
    <v-row class="h-100" justify="center" align="center">
      <v-col cols="8" class="mx-auto">
        <v-form v-model="valid">
          <v-container>
            <v-row justify="center">
              <v-col cols="4" md="4">
                <v-text-field outlined v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn color="green darken-4" class="white--text p-4">Reset Password</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-responsive>
</template>


<script>
export default {
  name: "password-reset",
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
};
</script>

<style scoped>
</style>
