import { render, staticRenderFns } from "./ConfirmPasswordReset.vue?vue&type=template&id=620e326f&scoped=true&"
import script from "./ConfirmPasswordReset.vue?vue&type=script&lang=js&"
export * from "./ConfirmPasswordReset.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620e326f",
  null
  
)

export default component.exports